<template>
  <section
    id="remainder-report"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <product-movement-modal
      v-if="selected.length === 1"
      :product="items.find((obj) => obj.product.id === selected[0]).product"
    />
    <categories-load-modal
      :included_categories="selected_categories"
      @add-categories="addCategories"
    />
    <div class="filters-row mt-4 mb-3">
      <div class="filter-item">
        <date-picker
          v-model="selectedDate"
          format="dd.MM.yyyy"
          class="elm-calendar"
          disabled
          placeholder="Выберите дату"
          :clearable="false"
        />
      </div>
      <div
        class="filter-item"
        @click.capture="showLoadModal"
      >
        <div class="categories-select">
          <span style="color: black">{{
            selected_categories?.length ? `Выбрано ${selected_categories?.length}` : 'Все категории'
          }}</span>
          <img
            src="/img/icons/arrow_up.svg"
            style="transform: rotate(180deg)"
          />
        </div>
      </div>
      <div class="filter-item">
        <i-select
          v-model="selectedSupplier"
          :label-in-value="true"
          :max-tag-count="1"
          :max-tag-placeholder="(val) => '+ Еще ' + val"
          filterable
          placeholder="Все поставщики"
          multiple
          @on-select="setSuppliers"
        >
          <i-option
            v-for="option of suppliers"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </i-option>
        </i-select>
      </div>
      <div class="filter-item">
        <i-select
          id="storage"
          v-model="selectedStorage"
          multiple
          filterable
          :max-tag-count="1"
          :max-tag-placeholder="(val) => '+ Еще ' + val"
          placeholder="Все места хранения"
          @on-select="setStorage"
        >
          <i-option
            v-for="item in storage_list"
            :key="item.id"
            :value="item.id"
          >
            <div>
              {{ item.name }}
            </div>
          </i-option>
        </i-select>
      </div>
      <div class="filter-item">
        <i-select
          id="remainders"
          v-model="selectedRem"
          placeholder="Отрицательные остатки"
          @on-select="setRem"
        >
          <i-option value="all"> Все остатки </i-option>
          <i-option value="negative"> Отрицательные остатки </i-option>
          <i-option value="positive"> Положительные остатки </i-option>
          <i-option value="zero"> Нулевые остатки </i-option>
        </i-select>
      </div>
      <div
        class="clear-text"
        @click="clearFilters"
      >
        Сбросить
      </div>
    </div>
    <b-row style="height: 100%">
      <b-col
        cols="12"
        class="d-flex flex-column"
        :class="table.total > 20 ? 'card-height' : ''"
      >
        <b-card
          class="p-0 m-0 card-size"
          no-body
          style="overflow: hidden; display: flex; flex: 1"
        >
          <div class="analytics-table-header">
            <div class="table-filter">
              <b-form-input
                v-model="searchString"
                type="text"
                class="filter-search"
                placeholder="Поиск"
                @input="search"
              />
            </div>
          </div>
          <negative-remainders-table
            :items="items"
            :selected_items="selected"
            :skip="table.pagination.skip"
            style="max-height: none; min-height: 1px; display: flex"
            :table_busy="loading"
            @setSelected="setSelected"
          />
        </b-card>
        <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
          <div class="mr-auto"></div>
          <custom-navbar
            style="position: absolute; left: 0; top: 0; right: 0"
            :items="selected"
            item_name="Товаров"
            :actions="[
              { id: 'inventory', label: 'Создать инвентаризацию', max: 100 },
              { id: 'movement', label: 'Показать товародвижение', max: 1 }
            ]"
            @setSelected="setSelected"
            @clear="setSelected([])"
            @inventory="createInventory('inventory')"
            @movement="showMovement()"
          />

          <div class="footer-data">Общее количество: {{ +total?.toFixed(3) || 0 }}</div>
        </div>
      </b-col>
    </b-row>
    <pagination

      style="padding-bottom: 20px"
      :total="total"
      table_name="remainders-table"
      @change_pagination="handlerPagination"
    />
  </section>
</template>

<script>
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal.vue'
  import { mapGetters } from 'vuex'
  import NegativeRemaindersTable from '@/views/diagnostics/components/NegativeRemaindersTable.vue'
  import { create_document_by_type } from '@/utils/create_document_by_type'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal'

  export default {
    name: 'NegativeRemainders',
    apollo: {
      Suppliers: {
        query: require('../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers = data.Suppliers ?? []
        }
      },
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data?.Storages ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      CurrentRemainders: {
        query: require('../gql/CurrentRemainders.graphql'),
        debounce: 500,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              remainder: this.remainder,
              storage: this.selected_storages,
              supplier: this.selected_suppliers,
              categories: this.selected_categories,
              pagination: {
                ...this.pagination,
                search: this.search
              }
            }
          }
        },
        result({ data }) {
          if (!data) return
          this.items = data?.CurrentRemainders?.remainders ? data?.CurrentRemainders?.remainders : []
          this.total = data?.CurrentRemainders?.total
          this.firstLoading = false
        }
      }
    },
    components: {
      ProductMovementModal,
      NegativeRemaindersTable,
      CategoriesLoadModal,
      Pagination,
      CustomNavbar
    },
    data() {
      return {
        selected: [],
        items: [],
        suppliers: [],
        storage_list: [],
        selected_categories: [],
        selectedDate: new Date(),
        selectedSupplier: [],
        selectedStorage: [],
        selectedRem: 'negative',
        searchString: '',
        total: 0,
        table: {
          pagination: {
            skip: 0,
            take: 20
          },
          total: 0,
          total_retail_sum: 0,
          total_purchase_sum: 0,
          total_delta: 0,
          items: []
        },
        selected_suppliers: [],
        selected_storages: [],
        search: '',
        remainder: 'negative',
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },
    methods: {
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.table.pagination.skip = Number((current_page - 1) * take)
        if (take) this.table.pagination.take = take
        this.pagination = this.table.pagination
      },
      addCategories(cats) {
        this.selected_categories = cats
      },
      setSuppliers(suppl) {
        const sup = suppl.values
        this.setSelected([])
        if (this.selected_suppliers.includes(sup)) {
          this.selected_suppliers = this.selected_suppliers.filter((el) => el !== sup)
          return
        }
        this.selected_suppliers.push(sup)
      },
      setStorage(str) {
        if (this.selected_storages.includes(str)) {
          this.selected_storages = this.selected_storages.filter((el) => el !== str)
          return
        }
        this.selected_storages.push(str)
      },
      setRem(val) {
        this.remainder = val
      },
      clearFilters() {
        this.selected_categories = []
        this.selected_suppliers = []
        this.selected_storages = []
        this.search = ''
        this.remainder = 'negative'
        this.pagination = {
          skip: 0,
          take: 20
        }
        this.searchString = ''
        this.selectedSupplier = []
        this.selectedStorage = []
      },
      showMovement() {
        this.$bvModal.show('product-movement')
      },

      setSelected(arr) {
        this.selected = arr
      },
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      async createInventory(type) {
        const doc = create_document_by_type(
          type,
          this.currentBranch.id,
          this.items
            .filter((el) => this.selected?.includes(el.product.id))
            .map((el) => {
              return {
                product: el.product.id,
                price: el.product.purchasePrice,
                retailPrice: el.product.retailPrice,
                delta: 0
              }
            }),
          this.items[0].storage.id
        )
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      loading() {
        return this.$apollo.queries.CurrentRemainders.loading
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .ivu-tag {
      max-width: 100px !important;
    }
    .ivu-select-selection {
      div {
        display: flex;
      }
    }

    #remainder-report {
      .b-filter-date {
        width: 208px;
        margin-top: 32px;
      }

      .chart-block {
        display: flex;
        flex: 1;
        max-height: 100%;

        .card {
          flex: 1;
          max-height: 100%;
          margin-bottom: 0px;

          .card-body {
            max-height: 100%;
          }
        }
      }

      .widget-height {
        height: 350px;
        margin-bottom: 16px;
      }

      .widget-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #313131;
      }

      .table-footer th {
        vertical-align: middle !important;
      }
    }

    .analytics-header-row {
      display: flex;
      margin-bottom: 16px;
    }

    .analytics-header-select {
      width: 260px;
    }

    .widget-row {
      display: flex;
    }

    .widget-total {
      white-space: nowrap;
      display: flex;
    }

    .analytics__item {
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #313131;

      &-title {
        font-size: 14px;
        line-height: 16px;
        color: #888888;
        margin-bottom: 8px;
      }
    }

    .analytics-table-header {
      input.filter-search {
        width: 350px;
        padding-left: 32px;
        background: url(/img/icons/input-search.svg) 12px center no-repeat;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .filter-item {
    margin-top: 20px;
    min-width: 240px !important;
    max-width: 350px !important;
  }
  .filters-row {
    margin-top: 0 !important;
    display: flex;
    flex-wrap: wrap;
  }
  #remainder-report {
    .categories-select {
      display: flex;
      flex-direction: row;
      background: white;
      border: 1px solid #bcbcbc;
      border-radius: 2px;
      height: 32px;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      justify-content: space-between;
    }
  }

  .filters-row {
    align-items: center;
    display: flex;

    .clear-text {
      cursor: pointer;
      margin-top: 16px;
    }

    .filter-item {
      margin-right: 16px;
      width: 20%;
    }
  }

  .analytics-table-header {
    padding: 16px;
  }
  .table-footer {
    position: relative;
  }

  .footer-data {
    position: relative;
    color: #313131;
    margin-left: 32px;
  }
  .incoming-table-footer {
    background-color: white;
    border: 1px solid #e1e1e1 !important;
    border-top: none !important;
  }

  .card-height {
    max-height: 66vh;
  }

  @media screen and (max-height: 800px) {
    .card-size {
      height: 52vh;
    }

    .card-height {
      max-height: 60vh;
    }
  }
</style>
