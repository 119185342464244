<template>
  <div>
    <b-table
      id="remainders-table"
      no-border-collapse
      sticky-header="60vh"
      :fields="table.fields"
      :items="items"
      class="rems-table"
      :busy="table_busy"
      show-empty
    >
      <template #empty>
        <p style="text-align: center; font-size: 18px; font-weight: 500; margin-top: 10px">Ничего не найдено</p>
      </template>
      <template #table-busy>
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div><strong>Загрузка...</strong></div>
        </div>
      </template>
      <template #head(id)>
        <b-form-checkbox v-model="select_all" />
      </template>

      <template #cell(id)="{ item }">
        <b-checkbox
          v-model="selected"
          :value="item.product.id"
          @change="setSelected"
        />
      </template>

      <template #cell(order)="{ index }">
        <span class="d-flex float-right">
          {{ +skip + index + 1 }}
        </span>
      </template>
      <template #cell(product)="{ item }">
        <div style="display: flex; flex-direction: column">
          {{ item.product?.name }}
          <div class="product-info-div">
            <span class="mr-2">{{ item.product?.articul }}</span
            ><span class="supplier">
              {{ item.product.supplier?.name ?? '-' }}
            </span>
          </div>
        </div>
      </template>
      <template #cell(storage)="{ item }">
        {{ item.storage.name }}
      </template>
      <template #cell(measurement)="{ item }">
        <span class="d-flex float-left">
          {{ item.product.measurement?.name ?? '-' }}
        </span>
      </template>
      <template #cell(remainders)="{ item }">
        <span class="d-flex float-right">
          {{ +item.remainder?.toFixed(3) || 0 }}
        </span>
      </template>
      <template #cell(category)="{ item }">
        {{ item.product.category?.name ?? '-' }}
      </template>
      <template #cell(purchasePrice)="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item.product.purchasePrice) }}
        </span>
      </template>
      <template #cell(retailPrice)="{ item }">
        <span class="d-flex float-right">
          {{ formatPrice(item.product.retailPrice) }}
        </span>
      </template>
    </b-table>
    <!-- <custom-navbar
      :items="selected"
      item_name="Товаров"
      :actions="[{ id: 'inventory', label: 'Создать инвентаризацию' }]"
      @inventory="createInventory('inventory')"
    /> -->
  </div>
</template>

<script>
  import { formatPrice } from '@/utils/helpers'
  import { create_document_by_type } from '@/utils/create_document_by_type'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NegativeRemaindersTable',
    props: {
      selected_items: Array,
      items: {
        type: Array,
        default: () => []
      },
      skip: {
        type: Number,
        default: () => 0
      },
      table_busy: {
        type: Boolean,
        default: () => true
      }
    },
    data() {
      return {
        selected: [],
        select_all: false,
        table: {
          busy: true,
          fields: [
            { key: 'id', label: '' },
            { key: 'order', label: '№' },
            { key: 'product', label: 'Наименование товара' },
            { key: 'category', label: 'Товарная группа' },
            { key: 'storage', label: 'Место хранения' },
            { key: 'measurement', label: 'Ед. изм.' },
            { key: 'remainders', label: 'Остатки' },
            { key: 'purchasePrice', label: 'Цена закупочная, ₽' },
            { key: 'retailPrice', label: 'Цена розничная, ₽' }
          ]
        }
      }
    },
    watch: {
      select_all(newVal) {
        if (!newVal) {
          this.clear()
          return (this.selected = [])
        }
        this.selected = this.items.map((el) => el.product.id)
        this.setSelected()
      },
      selected_items(val) {
        if (!val.length) {
          this.select_all = false
        } else {
          this.selected = val
        }
      }
    },
    methods: {
      setSelected() {
        this.$emit('setSelected', this.selected)
      },
      clear() {
        this.$emit('setSelected', [])
      },
      formatPrice,
      async createInventory(type = 'inventory') {
        const doc = create_document_by_type(
          type,
          this.currentBranch.id,
          this.items
            .filter((el) => this.selected?.includes(el.product.id))
            .map((el) => {
              return {
                product: el.product.id,
                price: el.product.purchasePrice,
                retailPrice: el.product.retailPrice,
                delta: 0
              }
            }),
          this.items[0].storage.id
        )
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    updated() {
      this.selected = this.selected_items
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .rems-table thead th {
      border-bottom: 1px solid #e1e1e1 !important;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .rems-table tbody tr td {
      border-bottom: 1px solid #e1e1e1 !important;
    }
  }
  .rems-table {
    margin-bottom: 0;
    width: 100%;
    min-width: 100%;
  }

  @media screen and (max-height: 800px) {
    .rems-table {
      margin-bottom: 0;
      height: 45vh;
    }
  }

  .product-info-div {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 380px;

    & > span {
      color: #888888;
      font-weight: 500;
    }

    & .supplier {
      color: #888888;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-height: 1rem;
    }
  }
</style>
