var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    staticClass: "rems-table",
    attrs: {
      "id": "remainders-table",
      "no-border-collapse": "",
      "sticky-header": "60vh",
      "fields": _vm.table.fields,
      "items": _vm.items,
      "busy": _vm.table_busy,
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function () {
        return [_c('p', {
          staticStyle: {
            "text-align": "center",
            "font-size": "18px",
            "font-weight": "500",
            "margin-top": "10px"
          }
        }, [_vm._v("Ничего не найдено")])];
      },
      proxy: true
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-checkbox', {
          attrs: {
            "value": item.product.id
          },
          on: {
            "change": _vm.setSelected
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "cell(order)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(+_vm.skip + index + 1) + " ")])];
      }
    }, {
      key: "cell(product)",
      fn: function (_ref3) {
        var _item$product, _item$product2, _item$product$supplie, _item$product$supplie2;

        var item = _ref3.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_vm._v(" " + _vm._s((_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.name) + " "), _c('div', {
          staticClass: "product-info-div"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s((_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.articul))]), _c('span', {
          staticClass: "supplier"
        }, [_vm._v(" " + _vm._s((_item$product$supplie = (_item$product$supplie2 = item.product.supplier) === null || _item$product$supplie2 === void 0 ? void 0 : _item$product$supplie2.name) !== null && _item$product$supplie !== void 0 ? _item$product$supplie : '-') + " ")])])])];
      }
    }, {
      key: "cell(storage)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.storage.name) + " ")];
      }
    }, {
      key: "cell(measurement)",
      fn: function (_ref5) {
        var _item$product$measure, _item$product$measure2;

        var item = _ref5.item;
        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm._v(" " + _vm._s((_item$product$measure = (_item$product$measure2 = item.product.measurement) === null || _item$product$measure2 === void 0 ? void 0 : _item$product$measure2.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : '-') + " ")])];
      }
    }, {
      key: "cell(remainders)",
      fn: function (_ref6) {
        var _item$remainder;

        var item = _ref6.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(+((_item$remainder = item.remainder) === null || _item$remainder === void 0 ? void 0 : _item$remainder.toFixed(3)) || 0) + " ")])];
      }
    }, {
      key: "cell(category)",
      fn: function (_ref7) {
        var _item$product$categor, _item$product$categor2;

        var item = _ref7.item;
        return [_vm._v(" " + _vm._s((_item$product$categor = (_item$product$categor2 = item.product.category) === null || _item$product$categor2 === void 0 ? void 0 : _item$product$categor2.name) !== null && _item$product$categor !== void 0 ? _item$product$categor : '-') + " ")];
      }
    }, {
      key: "cell(purchasePrice)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.product.purchasePrice)) + " ")])];
      }
    }, {
      key: "cell(retailPrice)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.product.retailPrice)) + " ")])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }