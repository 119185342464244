var render = function render(){
  var _vm$selected_categori, _vm$selected_categori2, _vm$total;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "height": "100%"
    },
    attrs: {
      "id": "remainder-report"
    }
  }, [_vm.selected.length === 1 ? _c('product-movement-modal', {
    attrs: {
      "product": _vm.items.find(function (obj) {
        return obj.product.id === _vm.selected[0];
      }).product
    }
  }) : _vm._e(), _c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.selected_categories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('div', {
    staticClass: "filters-row mt-4 mb-3"
  }, [_c('div', {
    staticClass: "filter-item"
  }, [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "format": "dd.MM.yyyy",
      "disabled": "",
      "placeholder": "Выберите дату",
      "clearable": false
    },
    model: {
      value: _vm.selectedDate,
      callback: function ($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('div', {
    staticClass: "filter-item",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "categories-select"
  }, [_c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s((_vm$selected_categori = _vm.selected_categories) !== null && _vm$selected_categori !== void 0 && _vm$selected_categori.length ? `Выбрано ${(_vm$selected_categori2 = _vm.selected_categories) === null || _vm$selected_categori2 === void 0 ? void 0 : _vm$selected_categori2.length}` : 'Все категории'))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })])]), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "label-in-value": true,
      "max-tag-count": 1,
      "max-tag-placeholder": function (val) {
        return '+ Еще ' + val;
      },
      "filterable": "",
      "placeholder": "Все поставщики",
      "multiple": ""
    },
    on: {
      "on-select": _vm.setSuppliers
    },
    model: {
      value: _vm.selectedSupplier,
      callback: function ($$v) {
        _vm.selectedSupplier = $$v;
      },
      expression: "selectedSupplier"
    }
  }, _vm._l(_vm.suppliers, function (option) {
    return _c('i-option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "id": "storage",
      "multiple": "",
      "filterable": "",
      "max-tag-count": 1,
      "max-tag-placeholder": function (val) {
        return '+ Еще ' + val;
      },
      "placeholder": "Все места хранения"
    },
    on: {
      "on-select": _vm.setStorage
    },
    model: {
      value: _vm.selectedStorage,
      callback: function ($$v) {
        _vm.selectedStorage = $$v;
      },
      expression: "selectedStorage"
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")])]);
  }), 1)], 1), _c('div', {
    staticClass: "filter-item"
  }, [_c('i-select', {
    attrs: {
      "id": "remainders",
      "placeholder": "Отрицательные остатки"
    },
    on: {
      "on-select": _vm.setRem
    },
    model: {
      value: _vm.selectedRem,
      callback: function ($$v) {
        _vm.selectedRem = $$v;
      },
      expression: "selectedRem"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v(" Все остатки ")]), _c('i-option', {
    attrs: {
      "value": "negative"
    }
  }, [_vm._v(" Отрицательные остатки ")]), _c('i-option', {
    attrs: {
      "value": "positive"
    }
  }, [_vm._v(" Положительные остатки ")]), _c('i-option', {
    attrs: {
      "value": "zero"
    }
  }, [_vm._v(" Нулевые остатки ")])], 1)], 1), _c('div', {
    staticClass: "clear-text",
    on: {
      "click": _vm.clearFilters
    }
  }, [_vm._v(" Сбросить ")])]), _c('b-row', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('b-col', {
    staticClass: "d-flex flex-column",
    class: _vm.table.total > 20 ? 'card-height' : '',
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "p-0 m-0 card-size",
    staticStyle: {
      "overflow": "hidden",
      "display": "flex",
      "flex": "1"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "analytics-table-header"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  })], 1)]), _c('negative-remainders-table', {
    staticStyle: {
      "max-height": "none",
      "min-height": "1px",
      "display": "flex"
    },
    attrs: {
      "items": _vm.items,
      "selected_items": _vm.selected,
      "skip": _vm.table.pagination.skip,
      "table_busy": _vm.loading
    },
    on: {
      "setSelected": _vm.setSelected
    }
  })], 1), _c('div', {
    staticClass: "table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c('custom-navbar', {
    staticStyle: {
      "position": "absolute",
      "left": "0",
      "top": "0",
      "right": "0"
    },
    attrs: {
      "items": _vm.selected,
      "item_name": "Товаров",
      "actions": [{
        id: 'inventory',
        label: 'Создать инвентаризацию',
        max: 100
      }, {
        id: 'movement',
        label: 'Показать товародвижение',
        max: 1
      }]
    },
    on: {
      "setSelected": _vm.setSelected,
      "clear": function ($event) {
        return _vm.setSelected([]);
      },
      "inventory": function ($event) {
        return _vm.createInventory('inventory');
      },
      "movement": function ($event) {
        return _vm.showMovement();
      }
    }
  }), _c('div', {
    staticClass: "footer-data"
  }, [_vm._v("Общее количество: " + _vm._s(+((_vm$total = _vm.total) === null || _vm$total === void 0 ? void 0 : _vm$total.toFixed(3)) || 0))])], 1)], 1)], 1), _c('pagination', {
    staticStyle: {
      "padding-bottom": "20px"
    },
    attrs: {
      "total": _vm.total,
      "table_name": "remainders-table"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }